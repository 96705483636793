
export const getDeviceId = async () => {
    try{
        const response = await (
            fetch("http://localhost:61001/id")
                .then(response=>response.json())
        );
        return response.id;
    } catch (e) {
        //console.error(e);
    }
    return false;
}
