import {getDeviceId} from "./lib/pi-service";

(async ()=>{

    const domApp = document.getElementById("app");
    const domLoading = document.createElement("div");
    domLoading.innerText = "Loading...";
    domLoading.id = "loading";

    const domCam = document.createElement("div");
    domCam.innerText = "Loading...";
    domCam.id = "cam";

    domApp.appendChild(domLoading);
    domApp.appendChild(domCam);

    const deviceId = await getDeviceId();

    // TODO: get my telephone number and start the room

    if(deviceId){
        domCam.innerHTML = "";
        new JitsiMeetExternalAPI("meet.palasthotel.de", {
            roomName: `device-${deviceId}`,
            parentNode: domCam,
            width: domCam.innerWidth,
            height: domCam.innerHeight,
        });

    } else {
        console.debug("is not pie");
    }



})();